
// toastController
import { alertController, IonGrid, IonPage, IonContent, loadingController, IonButton, IonInput, IonTextarea, IonHeader, IonTitle, IonToolbar, IonBackButton } from '@ionic/vue';
import { defineComponent, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { Analytics } from '../../../common/analytics';

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {  IonGrid,  IonPage, IonContent, IonButton, IonInput, IonTextarea, IonHeader, IonTitle, IonToolbar, IonBackButton },
  setup() {
    const router = useRouter();
    const screenName = "PAGE-POST_EDITOR";
    const analytics = new Analytics;

    const state = reactive({
      form:{
        title:'',
        text:'',
        tag:'',
        file:''
      },
        // selectFile: null,
      files : [{
        preview: "",
        src: {
          name: "",
          size: 0
        }
      }]
    });

    // methods
    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController
        .create({
          header: "알림",
          backdropDismiss: false,
          message: message,
          buttons: [
            {
              text: '확인',
              handler: () => {
                if ( callback ) {
                  callback();
                }
              },
            },
          ],
        });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    };

    // (message: any, callback: any = null)
    const presentConfirm = async (message: string, callback: any = null) => {
      const alert = await alertController
        .create({
          header: "알림",
          backdropDismiss: false,
          message: message,
          buttons: [
            {
              text: '확인',
              handler: () => {
                if ( callback ) {
                  callback();
                }
              },
            },
            {
              text: '취소'
            },
          ],
        });
      await alert.present();
    };

    const onFileSelect = async (e: Event) => {
      console.log(e);
      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files || !files[0]) {
        return;
      }

      // state.files.push(files[0]);
      console.log(files[0]);
      console.log(files[0].name);
      let fileExt = files[0].name.substring(
        files[0].name.lastIndexOf(".") + 1
      )
      fileExt = fileExt.toLowerCase()
      if (
        ["jpeg", "png", "gif", "bmp","jpg"].includes(fileExt) &&
        files[0].size <= 20971520 // 바이트
      ) {
        const reader = new FileReader()
        reader.onload = (e: Event) => {
          // base64
          const target = e.target as any;
          state.files.push({
            preview: target.result,
            src: files[0]
          });
        }
        reader.readAsDataURL(files[0] as unknown as Blob)
      } else if (20971520 <= files[0].size) {
        presentAlert('20mb 이하만 등록 가능합니다.');
      } else {
        presentAlert('이미지를 다시 선택해 주세요.');
      }
    }

    const onReset = async() => {
      presentConfirm('글 작성을 취소 하시겠습니까?',async () =>{
        window.history.length > 1 ? router.go(-1) : router.push('/')
      });
    };

    const onFileRemove = async(index: number) => {
      state.files.splice(index, 1);
    };

    const onSubmit = async() => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '등록 중입니다.',
      });
      await loading.present();

      let tag = state.form.tag;
      tag = tag.replace(/a/gi,"");
      let files = [{}];
      console.log(files);

      if ( 0 < state.files.length ) {
        files = [];
        for await (const file of state.files) {
          if ( file.src.name ) {
            const form = new FormData()
            form.append("uploadFile", file.src as unknown as Blob) // api file name
            await services.fileAdd(form).then(
              response => {
                const { data } = response;
                files.push({
                  url : data.name
                });
              }
            ).catch(error => {
              console.log(error);
            })
          }
        }
      } else {
        files = [];
      }

      const params = {
        content:{
          title: state.form.title,
          text: state.form.text,
          tag: tag,
          file: [{}]
        }
      }

      if ( 0 < files.length ) {
        params.content.file = files;
      } else {
        delete params.content.file;
      }

      services.postAdd(params)
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          setTimeout(function() {
            loading.dismiss();
            presentAlert('등록 되었습니다',async () =>{
              window.history.length > 1 ? router.go(-1) : router.push('/')
            });
          }, 1000);
        } else {
          setTimeout(function() {
            loading.dismiss()
          }, 1000);
        }
      })
      .catch(error => {
        let message = '';
        try {
          message = error.response.data.message;
        } catch (e) {
          message = '';
          console.log(e);
        }
        setTimeout(function() {
          loading.dismiss()
          presentAlert(message);
        }, 1000);
      });
    }

    onMounted(() => {
      console.log('onMounted');
      analytics.setScreenName(screenName);
    })

    return { state, onSubmit, onReset, onFileSelect, onFileRemove }
  }

});
